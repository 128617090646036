<template>
  <div>
    <el-main v-if="info">
      <div class="header">
        <div>
          <el-button type="primary" v-if="info.is_vip" size="small">VIP会员</el-button>
        </div>
      </div>
      <div class="user-info">
        <div class="title">1、会员信息</div>
        <div class="content padding between">
          <div class="pContent" style="width: 100%;">
            <span>会员姓名：{{ info.name }}</span>
            <span>联系电话：{{ info.mobile ? info.mobile : '--' }}</span>
            <span>注册方式：{{ info.register_type == 'wechat_mini' ? '小程序' : info.register_type == 'email' ? '邮箱' : info.register_type == 'mobile' ? '手机' : '--' }}</span>
            <span>注册账号：{{ info.mobile ? info.mobile : '--' }}</span>
            <span>注册时间：{{ getDateformat(info.create_time) }}</span>
            <span>会员到期时间：{{ getDateformat(info.card_user.end_time) }}</span>
            <span>
              会员头像：
              <el-image style="width: 60px; height: 60px" :src="info.avatar ? info.avatar : 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/beauty/defaultHeaderImg.png'"></el-image>
            </span>
            <span>性别：男</span>
          </div>
          <div class="replenish" style="width: 100%;">
            <div>补充信息</div>
            <div>
              <span>所在区域：{{ info.user_address.pro_city_area.province }}{{ info.user_address.pro_city_area.city }}{{ info.user_address.pro_city_area.area }}</span>
              <span>所属小区：{{ info.community_name }}</span>
              <span>详细地址：{{ info.user_address.address }}{{ info.user_address.door_no }}</span>
              <span>手机号验证：{{ info.mobile ? info.mobile : '--' }}</span>
              <span>邮箱验证：{{ info.email ? info.email : '--' }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card-info">
        <div class="title">
          <span>{{ cluster.length ? 3 : 2 }}、会员信息</span>
        </div>
        <div class="pContent">
          <span>是否会员：{{ info.is_vip ? '是' : '否' }}</span>
          <span>会员卡付费: {{ info.card_total_price }}</span>
        </div>
      </div>
      <div class="address">
        <div class="title">3.资产信息</div>
        <div class="contentTab">
          <el-table :data="info.in" :header-cell-style="{ 'background-color': '#F8F9FA' }">
            <el-table-column label="会员卡付费" align="center">
              <span style="color: blue; margin-right: 10px">￥{{ info.card_total_price }}</span>
              <el-button type="text" size="small" @click="vipShow = true">查看</el-button>
            </el-table-column>
            <el-table-column prop="mobile_phone" label="抵扣券总计数量（张）" align="center">
              <span style="color: blue; margin-right: 10px">{{ info.user_coupon_num }}</span>
              <el-button type="text" size="small">查看</el-button>
            </el-table-column>
            <el-table-column prop="combine_detail" label="抵扣券核销数量（张）" align="center">
              {{ info.user_coupon_used }}
            </el-table-column>
            <el-table-column prop="address" label="现有积分" align="center">
              <span style="color: blue; margin-right: 10px">{{ info.integral }}</span>
              <router-link :to="{ path: '/marketing/repairScoreDetail', query: { user_id: id } }" target="_blank">
                <el-button type="text" size="small">查看</el-button>
              </router-link>
              <el-button type="text" size="small" @click="integralShow = 1">送积分</el-button>
            </el-table-column>
            <el-table-column prop="address" label="冻结积分" align="center">
              <span style="color: blue">{{ info.freeze_integral }}</span>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="address">
        <div class="title">
          <span>{{ cluster.length ? 5 : 4 }}、消费信息</span>
          <div>
            <router-link :to="{ path: '/service/repairOrder', query: { user_id: id } }" target="_blank">
              <el-button type="text" size="small">查看预约订单</el-button>
            </router-link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <router-link :to="{ path: '/repair/goods/orderlist', query: { user_ids: id } }" target="_blank">
              <el-button type="text" size="small">查看商品订单</el-button>
            </router-link>
          </div>
        </div>
        <div class="contentTab">
          <el-table :data="info.in" :header-cell-style="{ 'background-color': '#F8F9FA' }">
            <el-table-column prop="truename" label="累计预约订单金额" align="center">
              <span style="margin-right: 10px">{{ info.service_total_price }}</span>
              <el-button type="text" size="small">查看</el-button>
            </el-table-column>
            <el-table-column label="累计预约订单数" align="center">
              {{ info.service_order_num }}
            </el-table-column>
            <el-table-column label="累计商品订单金额" align="center">
              <span style="margin-right: 10px">{{ info.goods_order_amount }}</span>
              <el-button type="text" size="small">查看</el-button>
            </el-table-column>
            <el-table-column label="累计商品订单数" align="center">
              {{ info.goods_order_num }}
            </el-table-column>
            <el-table-column label="服务累计退款金额" align="center">
              <span style="margin-right: 10px">{{ info.service_refund_amount }}</span>
              <el-button type="text" size="small">查看</el-button>
            </el-table-column>
            <el-table-column label="服务累计退款单数" align="center">
              {{ info.service_refund_num }}
            </el-table-column>
            <el-table-column label="累计商品退款金额" align="center">
              <span style="margin-right: 10px">{{ info.order_refund_amount }}</span>
              <el-button type="text" size="small">查看</el-button>
            </el-table-column>
            <el-table-column label="累计商品退款单数" align="center">
              {{ info.order_refund_amount }}
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="address">
        <div class="title">
          <span>{{ cluster.length ? 6 : 5 }}、地址管理</span>
          <router-link :to="{ path: '/user/userLog', query: { user_id: id } }" target="_blank">
            <el-button type="text" size="small">展开更多</el-button>
          </router-link>
        </div>
        <div class="content">
          <el-table :data="info.address" :header-cell-style="{ 'background-color': '#F8F9FA' }">
            <el-table-column prop="name" label="姓名" align="center"></el-table-column>
            <el-table-column prop="mobile" label="手机号" align="center"></el-table-column>
            <el-table-column prop="lastgoods" label="省市区域" align="center">
              <template slot-scope="scope">{{ scope.row.pro_city_area.province }}{{ scope.row.pro_city_area.city }}{{ scope.row.pro_city_area.area }}</template>
            </el-table-column>
            <el-table-column prop="address" label="详细地址" align="center"></el-table-column>
            <el-table-column prop="is_default" label="是否默认" align="center">
              <template slot-scope="scope">
                {{ scope.row.is_default ? '是' : '否' }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <el-dialog title="优惠券明细" :visible.sync="showMyCoupon" width="35%" center>
        <el-table :data="myCoupons" height="400px" :header-cell-style="{ 'background-color': '#F8F9FA' }">
          <template slot="empty">
            <No />
          </template>
          <el-table-column prop="create_time" label="领取时间" align="center">
            <template slot-scope="scope">
              {{ getDateformat(scope.row.create_time) }}
            </template>
          </el-table-column>
          <el-table-column prop="name" label="优惠券名称" align="center"></el-table-column>
          <el-table-column prop="status" label="状态" align="center">
            <template slot-scope="scope">
              {{ scope.row.status == 1 ? '未使用' : scope.row.status == 2 ? '已使用' : '已过期' }}
            </template>
          </el-table-column>
          <el-table-column prop="source" label="来源" align="center">
            <template slot-scope="scope">
              {{ scope.row.source == 1 ? '领取' : scope.row.source == 2 ? '后台赠送' : '开卡赠送' }}
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange($event, 1)"
            @current-change="handleCurrentChange($event, 1)"
            :current-page="myCouponsParameter.page"
            :page-size="myCouponsParameter.rows"
            :total="myCouponsParameter.total_number"
          ></el-pagination>
        </div>
      </el-dialog>
      <el-dialog title="积分明细" :visible.sync="showIntegral" width="35%" center>
        <el-table :data="myCoupons" height="400px" :header-cell-style="{ 'background-color': '#F8F9FA' }">
          <template slot="empty">
            <No />
          </template>
          <el-table-column prop="create_time" label="时间" align="center">
            <template slot-scope="scope">
              {{ getDateformat(scope.row.create_time) }}
            </template>
          </el-table-column>
          <el-table-column prop="type" label="用户行为" align="center">
            <template slot-scope="scope">
              {{ scope.row.type == 1 ? '签到送积分' : scope.row.type == 2 ? '购买商品送积分' : scope.row.type == 3 ? '取消兑换积分商品返还' : '兑换积分商品' }}
            </template>
          </el-table-column>
          <el-table-column prop="integral" label="积分" align="center">
            <template slot-scope="scope">
              {{ scope.row.type == 3 ? '-' + scope.row.integral : '+' + scope.row.integral }}
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange($event, 1)"
            @current-change="handleCurrentChange($event, 4)"
            :current-page="integralParameter.page"
            :page-size="integralParameter.rows"
            :total="integralParameter.total_number"
          ></el-pagination>
        </div>
      </el-dialog>
      <el-dialog title="余额明细" :visible.sync="showBalance" width="35%" center>
        <el-table :data="balance" height="400px" :header-cell-style="{ 'background-color': '#F8F9FA' }">
          <template slot="empty">
            <No />
          </template>
          <el-table-column prop="create_time" label="时间" align="center">
            <template slot-scope="scope">
              {{ getDateformat(scope.row.create_time) }}
            </template>
          </el-table-column>
          <el-table-column prop="name" label="用户行为" align="center">
            <template slot-scope="scope">
              {{ scope.row.type == 1 ? '后台赠送' : scope.row.type == 2 ? '开卡赠送' : scope.row.type == 3 ? '充值' : '支付扣余额' }}
            </template>
          </el-table-column>
          <el-table-column prop="num" label="金额" align="center"></el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination @current-change="handleCurrentChange($event, 2)" :current-page="balanceParameter.page" :total="balanceParameter.total_number"></el-pagination>
        </div>
      </el-dialog>
      <el-dialog title="成长值明细" :visible.sync="showGrowLog" width="35%" center>
        <el-table :data="growLog" height="400px" :header-cell-style="{ 'background-color': '#F8F9FA' }">
          <template slot="empty">
            <No />
          </template>
          <el-table-column prop="create_time" label="时间" align="center">
            <template slot-scope="scope">
              {{ getDateformat(scope.row.create_time) }}
            </template>
          </el-table-column>
          <el-table-column prop="name" label="用户行为" align="center">
            <template slot-scope="scope">
              {{ scope.row.type == 1 ? '后台赠送' : scope.row.type == 2 ? '开卡赠送' : scope.row.type == 3 ? '充值' : '支付扣余额' }}
            </template>
          </el-table-column>
          <el-table-column prop="num" label="成长值" align="center"></el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination @current-change="handleCurrentChange($event, 3)" :current-page="growLogParameter.page" :total="growLogParameter.total_number"></el-pagination>
        </div>
      </el-dialog>
      <!-- <el-dialog title="编辑信息" :visible.sync="showEditInfo" width="40%" center>
        <el-form label-width="100px">
          <el-form-item label="用户昵称：">{{ info.nickname }}</el-form-item>
          <el-form-item label="用户姓名：">
            <el-input v-model="editInfoForm.yonghuxingming"></el-input>
          </el-form-item>
          <el-form-item label="手机号码：">
            <el-input v-model="editInfoForm.mobile"></el-input>
          </el-form-item>
          <el-form-item label="会员生日：">
            <div class="shenri">
              <el-input></el-input>
              <span>年</span>
              <el-input></el-input>
              <span>月</span>
              <el-input></el-input>
              <span>日</span>
            </div>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input v-model="editInfoForm.yonghuxingbie" :rows="8" type="textarea"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showEditInfo = !1">取 消</el-button>
          <el-button type="primary" @click="comefromEditInfo">确 定</el-button>
        </span>
      </el-dialog> -->
      <!-- <Rechange
        :is_detail="1"
        :showRecharge="showRecharge"
        :id="id"
        :log="log"
        :rechange_num="rechange_num"
        :balance="info.shopCard ? info.shopCard.balance : ''"
        @closePopup="closePopup"
        @getList="getList"
      ></Rechange> -->
      <!-- <SendCoupon :showCoupon="showCoupon" :is_detail="1" :id="id" :coupon_id="coupon_id" :coupon_num="coupon_num" @closePopup="closePopup"></SendCoupon> -->

      <ul class="cardUl">
        <el-dialog title="会员卡" :visible.sync="vipShow">
          <li class="cardLi">
            <div class="content cardLiTab">
              <el-table :data="CardOrderVip" :header-cell-style="{ 'background-color': '#F8F9FA' }">
                <el-table-column prop="create_time" label="时间" align="center">
                  <template slot-scope="scope">
                    {{ getDateformat(scope.row.create_time) }}
                  </template>
                </el-table-column>
                <el-table-column prop="status" label="用户行为" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.status ? '已支付' : '未支付' }}
                  </template>
                </el-table-column>
                <el-table-column prop="price" label="金额" align="center"></el-table-column>
                <el-table-column prop="order_sn" label="交易单号" align="center"></el-table-column>
              </el-table>
              <div class="cardTabPage">
                <Paging :total="vipParameter.total_number" :page="vipParameter.page" :pageNum="vipParameter.rows" @updatePageNum="updateData"></Paging>
              </div>
            </div>
          </li>
        </el-dialog>
        <el-dialog title="送积分" :visible.sync="integralShow">
          <li class="cardLi">
            <div class="content cardLiTab">
              <el-form ref="form" :model="form" label-width="130px" label-position="left">
                <el-form-item label="当前积分：">{{ info.integral }}</el-form-item>
                <el-form-item label="变化：">
                  <el-radio-group v-model="type">
                    <el-radio label="增加"></el-radio>
                    <el-radio label="减少"></el-radio>
                    <el-radio label="最终改为"></el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="充值数值：">
                  <el-input v-model="integralNum" placeholder="请添加变化数值，增加/减少/改为"></el-input>
                </el-form-item>
                <el-form-item label="备注：">
                  <el-input type="textarea" v-model="form.desc" placeholder="此备注会显示在用户记录和商家后台"></el-input>
                </el-form-item>
              </el-form>
              <div class="formBtn">
                <el-button>取消</el-button>
                <el-button type="primary" @click="getSendIntegral(integralNum)">确定</el-button>
              </div>
            </div>
          </li>
        </el-dialog>
      </ul>
    </el-main>
  </div>
</template>

<script>
import { getDateformat } from '../../util/getDate';
//   import SendCoupon from './components/SendCoupon';
//   import Rechange from './components/Rechange';
import wx from '@/assets/image/wx.png';
import ali from '@/assets/image/ali.png';
import fash from '@/assets/image/fash.png';
import douyin from '@/assets/image/douyin.png';
import baidu from '@/assets/image/baidu.png';
import qq from '@/assets/image/qq.png';
import Paging from '@/components/paging';
export default {
  components: {
    //   SendCoupon,
    //   Rechange,
    Paging,
  },
  data() {
    return {
      url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      id: '',
      info: '',
      showMyCoupon: !1,
      showBalance: !1,
      showGrowLog: !1,
      showRecharge: !1,
      showCoupon: !1,
      showEditInfo: !0,
      showIntegral: !1,
      myCoupons: [],
      balance: [],
      integral: [],
      growLog: [],
      log: '',
      rechange_num: '',
      coupon_id: '',
      editInfoForm: {
        mobile: '',
        yonghuxingming: '',
        yonghuxingbie: '',
      },
      myCouponsParameter: {
        page: 1,
        rows: 10,
        total_number: 0,
      },
      balanceParameter: {
        page: 1,
        rows: 10,
        total_number: 0,
      },
      growLogParameter: {
        page: 1,
        rows: 10,
        total_number: 0,
      },
      vipParameter: {
        page: 1,
        rows: 5,
        total_number: 0,
      },
      integralParameter: {
        page: 1,
        rows: 10,
        total_number: 0,
      },
      group_color: [
        {
          color: '#CF984E',
          back: '#FFF6EB',
        },
        {
          color: '#409EFF',
          back: '#EBF4FF',
        },
        {
          color: '#FE6DA9',
          back: '#FEEAF2',
        },
        {
          color: '#34A853',
          back: '#E1F5E6',
        },
        {
          color: '#5654F3',
          back: '#EBEBFF',
        },
        {
          color: '#0BCED9',
          back: '#E1F4F5',
        },
      ],
      statistics: [],
      consumption: [],
      preference: [],
      act_reward: [],
      cluster: [],
      form: {},
      CardOrderVip: [],
      type: 1,
      // 会员卡
      vipShow: 0,
      // 积分
      integralShow: 0,
      integralNum: 0,
    };
  },
  watch: {
    showMyCoupon(val) {
      if (val && !this.myCoupons.length) this.getCoupons();
    },
    showBalance(val) {
      if (val && !this.balance.length) this.getBalance();
    },
    showGrowLog(val) {
      if (val && !this.growLog.length) this.getGrowLog();
    },
    showIntegral(val) {
      if (val && !this.integral.length) this.getIntegral();
    },
  },
  created() {
    let utils = this.$store.state.utils;
    utils.page = Number(this.$route.query.page);
    utils.rows = Number(this.$route.query.rows);
    utils.is_record = !0;
    this.id = this.$route.query.id;
    this.getUserInfo();

    this.getCardOrderList();
  },
  methods: {
    //获取积分
    getIntegral() {
      this.$axios
        .post(this.$api.user.integralLog, {
          user_id: this.id,
          page: this.integralParameter.page,
          rows: this.integralParameter.rows,
        })
        .then(res => {
          if (res.code == 0) {
            this.integral = res.result.list;
            this.integralParameter.total_number = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    // 获取用户信息
    getUserInfo() {
      this.$axios
        .post(this.$api.repair.user.userDetail, {
          id: this.id,
        })
        .then(res => {
          if (res.code == 0) {
            console.log(JSON.parse(JSON.stringify(res.result)));
            let result = res.result;
            this.statistics = [
              {
                order_amount: result.order_amount,
                order_num: result.order_num,
                integral: result.shopCard?.integral ?? '--',
                balance: result.shopCard?.balance ?? '--',
                grow_value: result.shopCard?.grow_value ?? '--',
                coupons_total: result.coupons_total,
              },
            ];
            this.consumption = [
              {
                //  xf_time: result.xf.xf_time,
                //  cost_price: result.xf.cost_price == 'NaN' ? '--' : result.xf.cost_price,
                //  xf_num: result.xf.xf_num,
                //  refund_amount: result.xf.refund_amount,
                // refund_num: result.xf.refund_num,
              },
            ];
            this.preference = [
              {
                //deep: result.preference.deep || '--',
                //goods_classify: result.preference.goods_classify || '--',
                //lastgoods: result.preference.lastgoods || '--',
                //last_add_cart_goods: result.preference.last_add_cart_goods || '--',
                //last_order_submit: result.preference.last_order_submit || '--',
              },
            ];
            if (result.cluster) {
              for (let i in result.cluster) {
                let index = Math.floor(Math.random() * 5);
                let color_style = this.group_color[index];
                result.cluster[i].back = color_style.back;
                result.cluster[i].color = color_style.color;
              }
              this.cluster = result.cluster;
            }
            if (result.card_set) {
              this.act_reward = [];
              if (result.card_set.act_reward.all_goods_by) {
                this.act_reward.push('全部商品包邮');
              }
              if (result.card_set.act_reward.goods_idscount) {
                this.act_reward.push(`会员享${result.card_set.act_reward.goods_idscount_num}折`);
              }
              if (Number(result.card_set.act_reward.is_coupons)) {
                this.act_reward.push(`赠送${result.card_set.act_reward.coupons_data.length}张优惠券`);
              }
              if (result.card_set.act_reward.is_money) {
                this.act_reward.push(`赠送${result.card_set.act_reward.money}元余额`);
              }
            }
            result.comefrom = result.comefrom == 1 ? wx : result.comefrom == 2 ? ali : result.comefrom == 3 ? fash : result.comefrom == 4 ? douyin : result.comefrom == 5 ? qq : baidu;
            this.info = result;
            this.info.in = [{id:1}];
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    // 会员卡
    getCardOrderList() {
      this.vipParameter.id = this.id;
      this.$axios.post(this.$api.repair.user.userCardOrderList, this.vipParameter).then(res => {
        if (res.code == 0) {
          this.CardOrderVip = res.result.list;
          this.vipParameter.total_number = res.result.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    updateData(val, status) {
      if (status == 0) {
        this.vipParameter.rows = val;
      } else {
        this.vipParameter.page = val;
      }
      this.getCardOrderList();
    },
    // 赠送积分
    getSendIntegral(integral) {
      this.integralShow = false;
      let type = 0;
      if (this.type == '增加') {
        type = 1;
      } else if (this.type == '减少') {
        type = 2;
      } else if (this.type == '最终改为') {
        type = 3;
      }
      this.$axios
        .post(this.$api.repair.user.userSendIntegral, {
          id: this.id,
          num: integral,
          type: type,
        })
        .then(res => {
          if (res.code == 0) {
            this.getUserInfo();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    //编辑信息
    comefromEditInfo() {
      this.$axios
        .post(this.$api.member.editUser, {
          id: this.id,
          ...this.editInfoForm,
        })
        .then(res => {
          if (res.code == 0) {
            console.log(res);
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    handleCurrentChange(val, type) {
      if (type == 1) {
        this.myCouponsParameter.page = val;
        this.getCoupons();
      } else if (type == 2) {
        this.balanceParameter.page = val;
        this.getBalance();
      } else if (type == 3) {
        this.growLogParameter.page = val;
        this.getGrowLog();
      } else if (type == 4) {
        this.integralParameter.page = val;
        this.getIntegral();
      }
    },
    frozen() {
      this.$confirm(`${this.info.isblack ? '请确认是否恢复此用户，恢复后用户可以正常下单？' : '请确认是否冻结此用户，冻结后，此用户无法下单？'}`, `${this.info.isblack ? '恢复' : '冻结'}`, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.user.setblack, {
            black: this.info.isblack ? 0 : 1,
            id: this.id,
          })
          .then(res => {
            if (res.code == 0) {
              this.info.isblack = this.info.isblack ? 0 : 1;
              this.$message({
                message: `${this.info.isblack ? '冻结成功' : ' 恢复成功'}`,
                type: 'success',
              });
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
    getCoupons() {
      this.$axios
        .post(this.$api.user.myCoupons, {
          id: this.id,
          page: this.myCouponsParameter.page,
          rows: this.myCouponsParameter.rows,
        })
        .then(res => {
          if (res.code == 200) {
            this.myCoupons = res.result.list;
            this.myCouponsParameter.total_number = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getBalance() {
      this.$axios
        .post(this.$api.user.balance, {
          id: this.id,
          page: this.balanceParameter.page,
          rows: this.balanceParameter.rows,
        })
        .then(res => {
          if (res.code == 200) {
            this.balance = res.result.list;
            this.balanceParameter.total_number = res.result.total;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getGrowLog() {
      this.$axios
        .post(this.$api.user.growLog, {
          id: this.id,
          page: this.growLogParameter.page,
          rows: this.growLogParameter.rows,
        })
        .then(res => {
          if (res.code == 200) {
            this.growLog = res.result.list;
            this.growLogParameter.total_number = res.result.total;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    closePopup() {
      this.showCoupon = !1;
      this.showRecharge = !1;
    },
    getDateformat(val) {
      return getDateformat(val);
    },
  },
};
</script>
<style lang="less" scoped>
.pContent,
.replenish > div:nth-child(2) {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-left: 100px;
  span {
    width: 25%;
    padding: 20px 0;
  }
}
.replenish {
  width: 100%;
}
.pContent .countVIP {
  width: 140px;
  height: 64px;
  box-shadow: 8px 8px 4px #ccc;
  background-color: yellow;
  padding: 10px;
}

.contentTab {
  margin: 30px 0 0 100px;
}
.el-main {
  background: #fff;
  font-size: 14px;
  .shenri {
    display: flex;
    span {
      margin: 0 5px;
    }
  }
  .title {
    line-height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
  }
  .header {
    display: flex;
    align-items: center;
  }
  .padding {
    padding: 30px 0px 30px 0px;
  }
  .between {
    justify-content: space-between;
    padding: 20px 0px 20px 0px;
    .left {
      display: flex;
    }
  }
  .user-info {
    .content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      p {
        height: 50px;
        line-height: 50px;
        margin-right: 64px;
      }
      p:nth-child(1) {
        display: flex;
        align-items: center;
        .el-image {
          width: 40px;
          height: 40px;
          margin-right: 8px;
        }
        img {
          width: 30px;
          height: 30px;
          margin-left: 10px;
        }
      }
      P:nth-child(6),
      p:nth-child(7) {
        margin: 0;
      }
    }
  }
  .card-info {
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      padding-left: 24px;
      background: #f8f9fb;
      p {
        margin-right: 64px;
      }
    }
  }
  .group {
    .content {
      padding: 20px 24px 10px 24px;
      background: #f8f9fb;
      display: flex;
      justify-content: space-between;
      align-items: center;
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          border-radius: 6px;
          padding: 0 20px;
          line-height: 36px;
          border: 1px solid;
          margin-right: 30px;
          margin-bottom: 10px;
        }
      }
      .el-button {
        margin-bottom: 10px;
      }
    }
  }
  .data {
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .content {
      display: flex;
      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px 0 20px;
        width: 290px;
        height: 105px;
        margin-right: 20px;
        border: 1px solid #edeef0;
        .left {
          p:nth-child(1) {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 8px;
          }
          p:nth-child(2) {
            color: #979899;
          }
        }
      }
    }
  }
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .address {
    margin-top: 10px;
  }
  /deep/ .el-dialog__header {
    text-align: left;
  }
  /deep/ .el-dialog__footer {
    text-align: right;
  }
}

.cardUl {
  width: 100%;
  background-color: #fff;
  margin-top: 50px;
  padding: 50px 0;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  li {
    position: relative;
    margin-bottom: 50px;
    padding-bottom: 100px;

    .cardLiTil {
      background-color: #ececec;
      color: gray;
      font-size: 18px;
      height: 50px;
      text-align: center;
      line-height: 50px;
    }

    .cardLiTab {
      padding: 20px;
    }

    .cardTabPage {
      display: flex;
      justify-content: center;

      position: absolute;
      bottom: 20px;
      left: 40%;
    }
  }
}

.formBtn {
  height: 100px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
</style>
